import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import { globalProps } from './utils/globalProps';
import ENVIRONMENT from './environment';

if (ENVIRONMENT.DATADOG_APPLICATION_ID && ENVIRONMENT.DATADOG_CLIENT_TOKEN) {
  /**
   * Some configuration values must be matching between RUM and logs.
   * https://github.com/DataDog/browser-sdk/tree/2427f67053ca6041dd81eb565ea0d49483811f81/packages/logs#initialization-parameters
   */
  const datadogSharedConfig = {
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: ENVIRONMENT.PRODUCTION,
    useCrossSiteSessionCookie: ENVIRONMENT.PRODUCTION,
    allowedTracingUrls: [/http(s)?:\/\/((?!ctst))[^.]*(\.services)?\.bitrise\.(io|dev)/],
  };

  const datadogConfig = {
    ...datadogSharedConfig,
    clientToken: ENVIRONMENT.DATADOG_CLIENT_TOKEN,
    service: 'website',
    env: ENVIRONMENT.PRODUCTION ? 'production' : 'development',
    version: `v${ENVIRONMENT.WEBSITE_RELEASE_NUMBER}`,
    silentMultipleInit: true,
  };

  datadogRum.init({
    ...datadogConfig,
    applicationId: ENVIRONMENT.DATADOG_APPLICATION_ID,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackLongTasks: true,
    trackResources: true,
  });

  const { user } = globalProps;

  if (user) {
    datadogRum.setUser({
      id: user.slug,
      isCreditBased: user.isCreditBased,
    });
  }

  datadogLogs.init({
    ...datadogConfig,
    forwardConsoleLogs: 'all',
  });
}

export type DurationVitalReference = ReturnType<typeof datadogRum.startDurationVital>;
